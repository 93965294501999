
















































































































































































import Vue from 'vue'
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
  BDropdown, BDropdownItem, BButton, BLink,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import { appGroupListTemplatesList } from '@/store/modules/app-group-list-templates/app-group-list-templates-list'
import store from '@/store'

let debounceTimeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 1000)

export default Vue.extend({
  name: 'GroupListTemplatesList',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BButton,
    BLink,

    vSelect,

    TableSpinner,
  },
  data() {
    return {
      currentRouteName: this.$router.currentRoute.name,
    }
  },
  computed: {
    programId() {
      return store.getters['verticalMenu/getDefaultProgram']
    },
    tableColumns() {
      return appGroupListTemplatesList.getters.getTableColumns
    },
    perPage() {
      return appGroupListTemplatesList.getters.getPerPage
    },
    totalItems() {
      return appGroupListTemplatesList.getters.getTotalItems
    },
    currentPage: {
      get() {
        return appGroupListTemplatesList.getters.getCurrentPage
      },
      set(val: number) {
        appGroupListTemplatesList.mutations.SET_CURRENT_PAGE(val)
      },
    },
    perPageOptions() {
      return appGroupListTemplatesList.getters.getPerPageOptions
    },
    searchQuery: {
      get() {
        return appGroupListTemplatesList.getters.getSearchQuery
      },
      set(val: string) {
        appGroupListTemplatesList.mutations.SET_SEARCH_QUERY(val)
      },
    },
    sortBy: {
      get() {
        return appGroupListTemplatesList.getters.getSortBy
      },
      set(val: string) {
        appGroupListTemplatesList.mutations.SET_SORT_BY(val)
      },
    },
    isSortDirDesc: {
      get() {
        return appGroupListTemplatesList.getters.getIsSortDirDesc
      },
      set(val: boolean) {
        appGroupListTemplatesList.mutations.SET_IS_SORT_DESC(val)
      },
    },
    dataMeta() {
      return appGroupListTemplatesList.getters.getDataMeta
    },
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.updateTable()
    },
    programId() {
      this.updateTable()
    },
    currentPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'currentPage', val)
      }
    },
    perPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'perPage', val)
      }
    },
    searchQuery(val, prevVal) {
      if (val !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(this, this.currentRouteName, 'searchQuery', val)
        }, 250)
      }
    },
  },
  mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    updatePerPage(val: number) {
      appGroupListTemplatesList.mutations.SET_PER_PAGE(val)
    },
    async fetchGroupListTemplatesList() {
      return appGroupListTemplatesList.actions.fetchGroupListTemplatesList()
    },
    updateTable() {
      (this.$refs.groupListTemplatesTable as BTable).refresh()
    },
    async deleteGroupListTemplate(id: string) {
      await appGroupListTemplatesList.actions.deleteGroupListTemplate(id)
      this.updateTable()
    },
    async cloneGroupListTemplate(id: string) {
      await appGroupListTemplatesList.actions.cloneGroupListTemplate(id)
      this.updateTable()
    },
    setFilterParams() {
      appGroupListTemplatesList.mutations.SET_FILTER_PARAMS()
    },
  },
})
